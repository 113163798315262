@import '../../index.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  overflow-x: hidden;
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .loginBox {
    display: flex;
    width: 456px;
    padding: 20px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
    border: 1px solid #dfc76c;
    background: rgba(223, 199, 108, 0.3);
    box-shadow: 0px 0px 10px 0px #dfc76c;

    h1 {
      color: #575755;
      font-family: Montserrat;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 2rem;
    }

    .emailIcon {
      position: absolute;
      background-color: transparent;
      top: 50%;
      left: 8px;
      transform: translateY(-45%);
      border-color: transparent;
      font-size: 22px;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .inputGroup {
        width: 100%;
        margin-bottom: 1.5rem;

        label {
          display: block;
          margin-bottom: 0.5rem;
          color: #FFFFFF;
          font-family: Montserrat;
          font-size: 1rem;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 0.5rem;
          padding-left: 2.5rem;
          border-radius: 0.5rem;
          color: #000;
          border: 1px solid #cdd1d6;
          box-shadow: 0px 1px 2px rgba(13, 16, 23, 0.06);
        }
      }

      .inputGroup {
        position: relative;
      }

      input {
        padding-right: 40px;
        /* Espaço para o ícone */
      }


      .emailIcon,
      .passwordIcon {
        position: absolute;
        background-color: transparent;
        top: 50%;
        transform: translateY(0%);
        font-size: 22px;
        color: #000;
      }

      .passwordIcon {
        left: 10px;
      }

      .emailIcon {
        left: 10px;
      }


      .showPasswordButton {
        position: absolute;
        background-color: transparent;
        top: 50%;
        right: 8px;
        color: #000;
        transform: translateY(-0%);
        border-color: transparent;
        font-size: 22px;
        cursor: pointer;
      }

      .loginButton {
        width: 100%;
        height: 50px;
        padding: 1rem;
        border-radius: 0.5rem;
        background: #dfc76c;
        color: #000;
        font-family: Montserrat;
        font-size: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        border: 1px solid #dfc76c;
        cursor: pointer;

        &:disabled {
          background: #e2e2e2;
          border: 1px solid #e2e2e2;
          cursor: not-allowed;
        }

        &:hover:enabled {
          background: #8b7c43;
          color: #2c2c2c;
          border: 1px solid #dfc76c;
        }
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .loginBox {
    width: 90% !important;
  }
}