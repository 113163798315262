@import '../../index.scss'; // Importe o arquivo de variáveis globais

.sidebar {
  // z-index: 99999;
  background-color: #FFF9E2 !important;
  .sidebarFooter {
    margin-top: auto;
    padding: 10px;
    text-align: center;
  }

  .leftAlign {
    text-align: left;
  }
  .marginLeft{
    margin-left: 2rem;
    width: 80%;
  }
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #d55f5a;
    font-family: Inter;
    font-size: 0.97394rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.39131rem;
    letter-spacing: -0.0195rem;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;

    p,
    h2 {
      margin: 0;
    }

    p {
      color: #757575;
      font-family: Inter;
      font-size: 0.69563rem;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1;
    }

    h2 {
      color: #000;
      font-family: Inter;
      font-size: 0.97394rem;
      font-weight: 500;
      line-height: 1;
    }
  }
  .avatar-icon .ant-avatar-icon {
    font-size: 24px;
  }
}
