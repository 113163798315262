@import '../../index.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.container {
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .steps {
    width: 100%;
  }

  .formInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      color: var(--Primaria-Davys-Grey, #575755);
      font-family: Inter;
      font-size: 1.04575rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.494rem; /* 142.857% */
    }
    input {
      padding: 0.5rem 0.5rem;
      width: 100%;
      border-radius: 0.59756rem;
      border: 1.195px solid var(--System-colors-Gray-300, #cdd1d6);
      background: var(--black-white-white, #fff);
      box-shadow: 0px 1.195px 2.39px 0px rgba(13, 16, 23, 0.06);
    }
  }

  .augustofoto02 {
    background: linear-gradient(270deg, rgba(115, 115, 115, 0) 46.31%, #d9d9d9 53.72%);
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    width: 100%;
    img {
      width: 100%;
      height: 100vh;
    }
  }

  .buttonNextStep {
    border-radius: 3.38944rem;
    background: #dfc76c;
    box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);
    width: 100%;
    border: 1px solid #dfc76c;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--black-white-white, #fff);
    font-family: Inter;
    font-size: 1.15388rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.01925rem;
    margin-top: 2rem;

    &:hover {
      border: 1px solid #dfc76c !important;
      color: #fff !important;
      background: transparent !important;
    }
  }

  .containerFormulario {
    border-radius: 0.57694rem;
    border: 1px solid #dfc76c;
    background: rgba(223, 199, 108, 0.3);
    backdrop-filter: blur(15px);
    width: 50%;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 20rem;
    margin: 2rem 0;

    .titleFormulario {
      color: #575755;
      text-align: center;
      font-family: Montserrat;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .buttonVoltar {
      top: 10px;
      left: 10px;
      position: absolute;
      background: transparent;
      padding: 0;
      border: none !important;
      svg {
        color: #fff;
        font-size: 2rem;
      }
      &:hover {
        background: transparent !important;
        border: none !important;
      }
    }

    .containerCheckbox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      span {
        color: #575755;
        font-family: Montserrat;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  .containerTexto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    width: 70%;
    margin: auto;
    img {
      height: 8rem;
    }
    h2 {
      color: #dfc76c;
      font-family: 'Times New Roman';
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .text01 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 140% */
    }
    .text02 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 155.556% */
    }
    .text03 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 175% */
    }
    .button {
      border-radius: 0.25rem;
      background: #dfc76c;
      box-shadow: 0px 0px 18.9px 6px rgba(223, 199, 108, 0.5);
      color: #1e1e1e;
      text-align: center;
      font-family: Montserrat;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      border: 1px solid #dfc76c !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      padding: 1.5rem;
      &:hover {
        border: 1px solid #dfc76c !important;
        background: #000 !important;
        color: #dfc76c !important;
      }
    }
  }

  .containerImagemHome {
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    img {
      max-height: 99vh;
    }
  }

  .conteudoTexto {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    p {
      color: #575755;
      font-family: Montserrat;
      font-size: 0.90831rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.81669rem; /* 200% */
      margin-left: 1rem;
      text-align: justify;
    }
    .containerBancario {
      border-radius: 0.5rem;
      background: #dfc76c;
      padding: 1rem;
      margin: 1rem 0;
      width: 100%;
      h2 {
        color: #575755;
        font-family: Montserrat;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
      }
      .containerPix {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        .botaoPix {
          background: #fff9e2;
          color: #dfc76c;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          margin-left: 1rem;
        }
      }
    }
    h3 {
      color: #575755;
      font-family: Montserrat;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.81669rem;
      margin-left: 1rem;
    }
  }

  .stepsContent {
    width: 100%;
    padding: 2rem 0;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
}

.buttonback {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 3.38944rem;
  background: transparent;
  box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);
  width: 100%;
  border: 1px solid #fff;
  padding: 1.5rem;
  color: var(--black-white-white, #fff);
  font-family: Inter;
  font-size: 1.15388rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.01925rem;
  margin-top: 2rem;

  &:hover {
    border: 1px solid #dfc76c !important;
    color: #fff !important;
    background: #dfc76c !important;
  }
}

/* Responsividade */
@media (max-width: 768px) {
  .containerFormulario {
    width: 90% !important;
    padding: 1rem;
  }

  .formInput {
    label {
      font-size: 1rem;
    }
    input {
      padding: 0.5rem;
      font-size: 1rem;
    }
  }

  .buttonNextStep {
    padding: 1rem;
    font-size: 1rem;
  }

  .buttonback {
    padding: 1rem;
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .containerFormulario {
    width: 100%;
    padding: 0.5rem;
  }

  .formInput {
    label {
      font-size: 0.875rem;
    }
    input {
      padding: 0.5rem;
      font-size: 0.875rem;
    }
  }

  .buttonNextStep {
    padding: 0.75rem;
    font-size: 0.875rem;
  }

  .buttonback {
    padding: 0.75rem;
    font-size: 0.875rem;
  }
}
