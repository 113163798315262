@import '../../index.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200&display=swap');


.tableContainer {
  width: calc(100% - 8rem);
  overflow-x: auto;
  
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem;

  .svgLogo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .loginContainer {
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    width: 50%;
    height: 100vh;
    z-index: 999;

    .logo {
      position: absolute;
      top: 20px;
      left: 20px;
      img {
        height: 10rem;
        border-radius: 0.5rem;
      }
    }
    // 12.938.291/3021-39

    .form {
      width: 80%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .textTitle {
        font-family: Poppins;
        text-align: left;
        font-size: 1.2rem;
        color: rgba(138, 136, 136, 0.9);
      }

      .logosCervejas {
        margin-top: 3rem;
        width: 80%;
      }
    }
    .formGroup {
      display: flex;
      flex-direction: column;
      margin-top: 1.5rem;
      width: 100%;

      label {
        color: #000;
        font-family: Poppins;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        text-align: flex-start;
        line-height: 1.5rem; /* 150% */
      }

      .inputWithIcon {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 0.5rem;
        border: 1px solid var(--system-colors-gray-300, #cdd1d6);
        background: var(--black-white-white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(13, 16, 23, 0.06);
        padding: 0 1rem;
        z-index: 999;

        input {
          border: none;
          box-shadow: none;
          flex: 1;
          height: 40px;
          &:focus {
            outline: none;
            border: none;
          }
        }
      }
    }
    .button {
      margin-top: 1.5rem;
      width: 100%;
      height: 2.5rem;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      // width: 80%;
      border-radius: 0.25rem;
      background: #e2272c;
      box-shadow: 0px 2px 2px 2px rgba(204, 204, 204, 0.1);

      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;

      color: #fff;
      font-family: Poppins;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem; /* 150% */

      z-index: 999;
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .loading {
      border: 4px solid rgba(255, 255, 255, 0.3);
      border-top: 4px solid #fff; /* Cor do topo do indicador de carregamento */
      border-radius: 50%;
      width: 22px;
      height: 22px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
  }

  .rigthPartLogin {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--degrade, linear-gradient(162deg, #ee1c23 4.83%, #ae5757 103.44%));
    // filter: blur(2.5px);

    height: 100vh;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      background: var(--degrade, linear-gradient(162deg, #ee1c23 4.83%, #ae5757 103.44%));
      filter: blur(2.5px);
    }
    h2 {
      width: 90%;
      color: #eee;
      font-family: Poppins;
      font-size: 1.5rem;
      font-weight: 700;
      text-align: left;
    }
    p {
      width: 90%;
      color: #eee;
      font-family: Poppins;
      font-size: 1rem;
      font-weight: 700;
      text-align: left;
    }
  }
}

label {
  font-weight: bold;
  font-size: 12px;
}

.truncatedText {
  display: inline-block;
  max-width: 150px; // ajuste este valor conforme necessário
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.columnEllipsis {
  max-width: 8rem; // Defina a largura máxima desejada para as células
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1024px) {
  .container {
    min-height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .rigthPartLogin {
      width: 100%;
    }

    .loginContainer {
      width: 100%;

      .logo {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        img {
          height: 4rem;
          border-radius: 0.5rem;
        }
      }
    }
  }
}
