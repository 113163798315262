$vermelho-forte: #EB5757;
$primaria-cinza: #3e3e3d;
$secundario-cinza: #4e4e4e;
$Bg---editavel: linear-gradient(0deg, rgba(226, 39, 44, 0.68) 0%, rgba(226, 39, 44, 0.68) 100%), #fff;
$system-colors-gray-300: #cdd1d6;
$Bg---no-digitavel: #AFAFAF;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: 'Inter', sans-serif;
}

p, h1 {
  margin: 0;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #DFC76C !important; // Cor de fundo para steps completados
  border-color: #DFC76C !important; // Cor da borda para steps completados
}

// Cor do ícone (checkmark) para steps completados
.ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
  color: white !important;
}

// Sobrescreve a cor da linha de conexão para steps completados
.ant-steps-item-finish .ant-steps-item-tail::after {
  background-color: #DFC76C !important; // Cor da linha para steps completados
}

// Cor para o step atual/em processo
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #DFC76C !important;
  border-color: #DFC76C !important;
}

.ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #DFC76C;
}
.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #DFC76C;
}

.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #FFF9E2 !important;
  border: 2px dashed #DFC76C !important;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-steps-vertical{
  flex-direction: row !important;
}

.ant-table-tbody {
  background-color: #FFFFFF;
}

.ant-breadcrumb ol {
  padding-left: 1rem;
}

.ant-input-outlined[disabled] { 
  color: black;
}
