@import '../../index.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
.container {
  background: #000;
  overflow-x: hidden;

  .containerTexto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    width: 70%;
    margin: auto;
    img {
      height: 8rem;
    }
    h2 {
      color: #dfc76c;
      font-family: "Times New Roman";
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    .text01 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 140% */
    }
    .text02 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 155.556% */
    }
    .text03 {
      color: #fffcf1;
      font-family: Montserrat;
      margin-top: 1rem;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem; /* 175% */
    }
    a{
      width: 100%;
    }
    .button {
      border-radius: 0.25rem;
      background: #dfc76c;
      box-shadow: 0px 0px 18.9px 6px rgba(223, 199, 108, 0.5);
      color: #1e1e1e;
      text-align: center;
      font-family: Montserrat;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      border: 1px solid #dfc76c !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
      padding: 1.5rem;
      &:hover{
        border: 1px solid #dfc76c !important;
        background: #000 !important;
        color: #dfc76c !important;
      }  
    }
  }
  .containerImagemHome {
    height: 100vh;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    img {
      // width: 100%;
      max-height: 99vh;
    }
  }
}
